<template>
  <div>
    <div v-if="!addVisible">
      <div class="card">
        <el-button type="primary" @click="addVisible = true">新建回收公式</el-button>
      </div>
      <div class="table">
        <div class="table">
          <el-table
            :data="tableData.list"
            style="width: 100%"
            v-loading="loading"
            height="700"
            stripe
          >
            <template v-for="item in rowList">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :width="item.t_width">
                <template slot-scope="scope">
                  <template v-if="item.field_alias !== 'status'">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </template>
                  <template v-else>
                    <el-switch
                      v-model="scope.row[item.field_alias]"
                      @change="handleChange(scope.row)"
                      :active-value="10"
                      :inactive-value="20">
                    </el-switch>
                  </template>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handleEdit(scope.row)">修改</el-button>
                <el-button type="text" style="color: #F56C6C;" @click="handleDel(scope.row.recovery_formula_id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Add v-if="addVisible" @onBack="handleBack" :currRow="currRow" />
  </div>
</template>

<script>
import { getListReq, delReq } from '@/api/system/formula/list.js';
import Add from './Add.vue';

export default {
  name: 'FormulaOld',
  data() {
    return {
      currRow: {},
      addVisible: false,
      loading: false,
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '货品分类', field_alias: 'goods_type' },
        { field_text: '公式', field_alias: 'expression_str' },
        { field_text: '启用', field_alias: 'status' },
      ],
      tableData: {
        list: [{}],
        total: 0,
      },
      page: 1,
      limit: 99999,
    };
  },
  components: {
    Add,
  },
  created() {
    this.getList();
  },
  methods: {
    handleEdit(row) {
      this.addVisible = true;
      this.currRow = row;
    },
    handleBack(str) {
      if (str) {
        this.getList();
      }
      this.addVisible = false;
      this.currRow = {};
    },
    handleChange(row) {
      delReq({
        recovery_formula_id: row.recovery_formula_id,
        status: row.status,
      })
    },
    handleDel(recovery_formula_id) {
      this.$confirm('确定要删除该回收公式吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        delReq({
          recovery_formula_id,
          is_del: 20,
        })
          .then((res) => {
            if (res.code === 1) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              this.getList();
            }
          });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },
    getList() {
      getListReq({
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          if (res.code === 1) {
            const list = res.data.list.map((item, index) => {
              item.index = index + 1;
              return item;
            });
            this.tableData = {
              list,
              total: res.data.total,
            };
            console.log(this.tableData)
          }
        });
    },
  },
};
</script>
