<template>
  <div v-show="show">
    <div class="card">
      <el-page-header @back="goBack" :content="content">
      </el-page-header>
    </div>
    <div class="form">
      <el-form :model="form" label-width="120px">
        <el-form-item  label="货品分类：">
          旧料
        </el-form-item>
        <el-form-item  label="已选品类：">
          <span v-for="(item, index) in nodes" :key="item.goods_type_id">{{item.goods_type_name}}<span v-if="index + 1 !== nodes.length">、</span></span>
          <el-button type="primary" size="mini" style="margin-left: 10px;" @click="dialogVisible = true">选择品类</el-button>
        </el-form-item>
        <el-form-item  label="变量：">
          <el-button size="mini" style="margin-left: 10px;" v-for="item in attrArr" :key="item.attr_id" @click="handleClickAttr(item)">
            {{item.attr_name}}
          </el-button>
        </el-form-item>
        <el-form-item label="常量：">
          <el-popover
            placement="bottom"
            width="200"
            v-model="visible">
            <el-input  size="mini" v-model="value" placeholder="请输入数字，最多四位小数" type="number"></el-input>
            <div style="text-align: right; margin-top: 10px;">
              <el-button size="mini" type="text" @click="visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="handleValue">确定</el-button>
            </div>
            <el-button slot="reference" size="mini">数字</el-button>
          </el-popover>
        </el-form-item>
        <el-form-item  label="运算符：">
          <el-button size="mini" style="margin-left: 10px;" @click="hanldeFu('+')">+</el-button>
          <el-button size="mini" style="margin-left: 10px;" @click="hanldeFu('-')">-</el-button>
          <el-button size="mini" style="margin-left: 10px;" @click="hanldeFu('*')">*</el-button>
          <el-button size="mini" style="margin-left: 10px;" @click="hanldeFu('/')">/</el-button>
          <el-button size="mini" style="margin-left: 10px;" @click="hanldeFu('(')">(</el-button>
          <el-button size="mini" style="margin-left: 10px;" @click="hanldeFu(')')">)</el-button>
        </el-form-item>
        <el-form-item  label="计算公式：">
          <div style="display: flex; align-items: center;">
            <span>回收金额 = </span>
            <div class="compute" :style="expression_str? {color: 'black'} : ''">
              {{expression_str ? expression_str : '请填写计算公式'}}
            </div>
            <el-button type="danger" size="small" @click="handleReset">重置</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSumbit">确认</el-button>
          <el-button @click="goBack">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="选择品类"
      :visible.sync="dialogVisible"
      :modal="modal"
      width="50%">
      <el-tree
        class="tree-line"
        :indent="0"
        ref="tree"
        :data="treeData"
        show-checkbox
        node-key="goods_type_id"
        :default-expanded-keys="expandedKeys"
        :props="defaultProps"
        :default-expand-all="true">
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getTypeReq, getRecExpressionFieldReq, addReq } from '@/api/system/formula/list';

export default {
  data() {
    return {
      value: '', // 常量
      visible: false,
      modal: true,
      show: true,
      recovery_formula_id: '',
      expression: '',
      expression_field: '',
      expression_str: '',
      attrArr: [],
      goods_type_id: '',
      nodes: [],
      treeData: [],
      expandedKeys: [],
      dialogVisible: false,
      content: '新建回收公式',
      form: {},
      defaultProps: {
        children: 'children',
        label: 'goods_type_name',
      },
    };
  },
  props: {
    currRow: Object,
  },
  created() {
    if (this.currRow.recovery_formula_id) {
      this.content = '修改回收公式';
      this.modal = false;
      this.show = false;
      this.recovery_formula_id = this.currRow.recovery_formula_id;
      this.goods_type_id = this.currRow.goods_type_id;
      this.expression_str = this.currRow.expression_str;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.currRow.goods_type_id.split(','));
        setTimeout(() => {
          this.handleTrue();
        }, 500);
        setTimeout(() => {
          this.show = true;
          this.modal = true;
        }, 1000);
      });
    } else {
      this.getRecExpressionField();
    }
    this.getType();
  },
  methods: {
    // 常量
    handleValue() {
      this.expression_str += this.value;
      this.expression += this.value;
      this.visible = false;
    },
    onSumbit() {
      addReq({
        recovery_formula_id: this.recovery_formula_id,
        goods_type_id: this.goods_type_id,
        expression: this.expression,
        expression_field: this.expression_field,
        expression_str: this.expression_str,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$emit('onBack', 'getList');
          }
        });
    },
    hanldeFu(str) {
      this.expression += str;
      this.expression_str += str;
    },
    handleReset() {
      this.expression = '';
      this.expression_field = '';
      this.expression_str = '';
    },
    handleClickAttr(item) {
      const { attr_id, attr_name } = item;
      this.expression_str += attr_name;
      this.expression_field += attr_id + ',';
      this.expression += "${" + item.attr_id + "}";
    },
    // 获取字段
    getRecExpressionField() {
      getRecExpressionFieldReq({
        goods_type_id: this.goods_type_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.attrArr = res.data;
          }
        });
    },
    handleTrue() {
      const nodes = this.$refs.tree.getCheckedNodes();
      const goods_type_id = this.$refs.tree.getCheckedKeys();
      console.log(nodes);
      this.nodes = nodes;
      this.goods_type_id = goods_type_id.join(',');
      this.dialogVisible = false;
      this.getRecExpressionField();
      // if (!this.currRow.recovery_formula_id) {
      //   this.handleReset();
      // }
    },
    getType() {
      getTypeReq({
        type: 20,
      })
        .then((res) => {
          if (res.code === 1) {
            this.treeData = res.data;
          }
        });
    },
    goBack() {
      this.$emit('onBack');
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  background: white;
  padding: 20px 0;
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .compute {
    width: 40%;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 0 10px;
    box-sizing: border-box;
    color: #ccc;
    margin: 0 5px;
    z-index: 9;
    background: white;
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
